import * as React from "react";
import {
  Progress,
  LoadingOverlay,
  Title,
  Divider,
  Table,
  Text,
  Card,
  ThemeIcon,
  Group,
} from "@mantine/core";
import { Check, X } from "tabler-icons-react";
import Countdown from "react-countdown";
import moment from "moment";
import { DirectusContext } from "../../../Provider/Directus";

export default class Entry extends React.Component {
  static contextType = DirectusContext;

  state = {
    tick: true,
    loading: false,
  };
  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ tick: !this.state.tick }),
      1000
    );
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  isFinished() {
    return (
      this.props.data.dough.phases.length - 1 ===
      this.props.data.dough.phases.indexOf(this.props.data.phase.id)
    );
  }
  render() {
    let progress = [];
    const finishDate = moment(this.props.data.phase_started).add(
      this.props.data.phase.duration,
      "minutes"
    );

    const t = moment().diff(moment(this.props.data.phase_started), "seconds");
    const y = this.props.data.dough.warning * 60;
    const d = this.props.data.phase.duration * 60;
    const r = this.props.data.phase.duration * 60 - y;
    const ended = moment().isSameOrAfter(finishDate);

    const redProgress = (Math.min(t, r) / d) * 100;
    const warningProgress = (Math.min(y, Math.max(0, t - r)) / d) * 100;

    progress.push({
      value: redProgress,
      color: "red",
    });
    progress.push({
      value: warningProgress,
      color: "yellow",
    });
    // const restTime = finishDate.diff(moment(), "seconds");

    // const percentage = restTime / (this.props.data.phase.duration*60) * 100;

    const warning =
      moment().isSameOrAfter(
        moment(finishDate).subtract(this.props.data.dough.warning, "minutes")
      ) &&
      moment().isBefore(finishDate) &&
      !ended;
    const processing = moment().isBefore(
      moment(finishDate).subtract(this.props.data.dough.warning, "minutes")
    );

    return (
      <Card
        key={this.props.data.id + "_sub"}
        style={{ marginTop: 5, padding: 0 }}
        shadow="sm"
      >
        <LoadingOverlay visible={this.state.loading} />
        <Table>
          <tbody>
            <tr>
              <td style={{ width: 0 }}>
                {ended && (
                  <ThemeIcon radius="xl" size="xl" color="green">
                    <Check size={18} />
                  </ThemeIcon>
                )}

                {warning && (
                  <ThemeIcon radius="xl" size="xl" color="yellow">
                    <X size={18} />
                  </ThemeIcon>
                )}

                {processing && (
                  <ThemeIcon radius="xl" size="xl" color="red">
                    <X size={18} />
                  </ThemeIcon>
                )}
              </td>
              <td style={{ width: "30%" }}>
                <Title order={1}>
                  {this.props.data.dough.name} - K{this.props.data.bowl}
                </Title>
              </td>
              <td style={{ width: "70%", position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    zIndex: 10000,
                    right: 20,
                    height: "100%",
                  }}
                >
                  <Text color="dark">
                    <Group>
                      <div style={{ textAlign: "right" }}>
                        <Text size="xl">{this.props.data.phase.name}</Text>
                        <Text size="xs" weight="bold">
                          PHASE
                        </Text>
                      </div>
                      <Divider sx={{ height: 50 }} orientation="vertical" />
                      <div>
                        <Text size="xl" weight="bold">
                          <Countdown
                            autoStart={false}
                            date={finishDate.toDate()}
                            daysInHours={true}
                          >
                            <Text size="xl" weight="bold">
                              FERTIG
                            </Text>
                          </Countdown>
                        </Text>
                        <Text size="xs" weight="bold">
                          RESTZEIT (min.)
                        </Text>
                      </div>
                    </Group>
                  </Text>
                </div>
                <Progress
                  sections={ended ? undefined : progress}
                  style={{ height: 50 }}
                  color={"green"}
                  radius="xs"
                  size="xl"
                  value={100}
                />
              </td>
              {/* <td style={{width: '20%',whiteSpace: 'nowrap',textAlign:"right"}}>
              </td>
              <td style={{whiteSpace: 'nowrap'}}>
              </td> */}
            </tr>
          </tbody>
        </Table>
      </Card>
    );
  }
}
