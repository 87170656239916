import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App'
import { DirectusProvider } from './Provider/Directus';
import { MantineProvider } from '@mantine/core';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MantineProvider theme={{ colorScheme: 'light' }} withGlobalStyles withNormalizeCSS>
      <BrowserRouter>
        <DirectusProvider options={{
          auth: {
            mode: "json",
            autoRefresh: true,
          }
        }} apiUrl="https://schaper.k9y.de">
          <App />
        </DirectusProvider>
      </BrowserRouter>
    </MantineProvider>
  </React.StrictMode>
);
