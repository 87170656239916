import * as React from "react";
import { DirectusContext } from "../../Provider/Directus";
import Header from "../../Components/Header";
import { AppShell, Container } from "@mantine/core";
import moment from "moment";
import AddNew from "../../Components/AddNew";
import Entry from "./Partials/Entry";

export default class Dashboard extends React.Component {
  static contextType = DirectusContext;

  state = {
    addNew: false,
    loading: true,
    data: [],
  };

  async onAddNewClose() {
    this.setState({ addNew: false });
    await this.update();
  }

  async componentDidMount() {
    await this.update();
    this.setState({ loading: false });
    this.interval = setInterval(() => this.update(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  async update() {
    let { data } = await this.context.directus.items("timers").readByQuery({
      limit: -1,
      fields: ["*.*"],
      deep: {
        dough: {
          phases: {
            sort: ["sort"],
          },
        },
      },
    });
    await this.context.directus.items("timers").deleteMany(
      data
        .filter((el) => {
          const finishDate = moment(el.phase_started).add(
            el.phase.duration + 15,
            "minutes"
          );
          const ended = moment().isSameOrAfter(finishDate);
          return ended;
        })
        .map((el) => el.id)
    );
    this.setState({ data });
  }

  render() {
    return (
      <AppShell
        header={<Header />}
        styles={(theme) => ({
          main: {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        })}
      >
        {this.state.addNew && (
          <AddNew open onClose={this.onAddNewClose.bind(this)} />
        )}
        <Container fluid>
          {this.state.data.map((el) => (
            <Entry onChange={this.update.bind(this)} key={el.id} data={el} />
          ))}
        </Container>
      </AppShell>
    );
  }
}
