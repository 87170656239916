import * as React from "react";
import { Modal, LoadingOverlay, Group, Button,Select } from '@mantine/core';
import { DirectusContext } from '../Provider/Directus';

export default class AddNew extends React.Component {
  static contextType = DirectusContext
  state={
    loading:true,
    selected: undefined,
    bowl: 1,
    data:[]
  }
  async componentDidMount(){
    const {data} = await this.context.directus.items('dough').readByQuery({
      limit:-1,
      deep:{
        phases: {
          sort: ["sort"]
        }
      }
    })
    this.setState({data, loading:false})
  }
  async onSubmit(e){
    e.preventDefault();
    if(this.state.selected){
      this.setState({loading:true})
      let phase = await this.context.directus.items("phase").readOne(this.state.selected)
      await this.context.directus.items("timers").createOne({
        dough: phase.dough,
        phase: phase.id,
        bowl: this.state.bowl
      });
      this.props.onClose()
    }
    this.setState({loading:false, selected:undefined,})

  }
  render() {
    return (

      <Modal
        opened={!!this.props.open}
        onClose={this.props.onClose.bind(this)}
        title="Neuen Timer starten"
      >
        <LoadingOverlay visible={this.state.loading} />
        <form onSubmit={this.onSubmit.bind(this)}>
          <Select
            label="Teigart"
            required
            value={this.state.selected}
            onChange={selected=>this.setState({selected})} 
            style={{marginBottom: 20}}
            data={this.state.data.map(el=>({value:el.phases[0],label:el.name}))}
          />
          <Select
            label="Kesselnummer"
            required
            value={this.state.bowl}
            onChange={bowl=>this.setState({bowl})} 
            style={{marginBottom: 20}}
            data={[...Array(30).keys()].map(el=>({value:el+1,label:el+1}))}
          />
          <Group position="center">
            <Button type="submit">Starten</Button>
          </Group>
        </form>
      </Modal>
    );
  }
}