import * as React from "react";
import {
  LoadingOverlay,
  Group,
  Button,
  Table,
  Text,
  Card,
  ThemeIcon,
  ActionIcon,
} from "@mantine/core";
import { ArrowBigRightLines, Check, X } from "tabler-icons-react";
import Countdown from "react-countdown";
import moment from "moment";
import { DirectusContext } from "../../../Provider/Directus";

export default class Entry extends React.Component {
  static contextType = DirectusContext;

  state = {
    tick: true,
    loading: false,
  };
  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ tick: !this.state.tick }),
      1000
    );
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  async onDelete() {
    this.setState({ loading: true });
    if (window.confirm("Möchten Sie den Timer wirklich löschen?") === true) {
      await this.context.directus.items("timers").deleteOne(this.props.data.id);
      this.props.onChange();
    }
    this.setState({ loading: false });
  }
  async onNext() {
    this.setState({ loading: true });
    const phase =
      this.props.data.dough.phases[
        this.props.data.dough.phases.indexOf(this.props.data.phase.id) + 1
      ];
    await this.context.directus.items("timers").updateOne(this.props.data.id, {
      phase_started: moment().local().format("YYYY-MM-DDTHH:mm:ss"),
      phase,
    });
    this.props.onChange();
    this.setState({ loading: false });
  }
  isFinished() {
    return (
      this.props.data.dough.phases.length - 1 ===
      this.props.data.dough.phases.indexOf(this.props.data.phase.id)
    );
  }
  render() {
    const finishDate = moment(this.props.data.phase_started).add(
      this.props.data.phase.duration,
      "minutes"
    );
    const ended = moment().isSameOrAfter(finishDate);
    const warning =
      moment().isSameOrAfter(
        moment(finishDate).subtract(this.props.data.dough.warning, "minutes")
      ) &&
      moment().isBefore(finishDate) &&
      !ended;
    const processing = moment().isBefore(
      moment(finishDate).subtract(this.props.data.dough.warning, "minutes")
    );
    return (
      <Card
        key={this.props.data.id + "_sub"}
        style={{ marginTop: 10 }}
        shadow="sm"
      >
        <LoadingOverlay visible={this.state.loading} />
        <Table>
          <tbody>
            <tr>
              <td style={{ width: 0 }}>
                {ended && (
                  <ThemeIcon radius="xl" size="xl" color="green">
                    <Check size={18} />
                  </ThemeIcon>
                )}

                {warning && (
                  <ThemeIcon radius="xl" size="xl" color="yellow">
                    <X size={18} />
                  </ThemeIcon>
                )}

                {processing && (
                  <ThemeIcon radius="xl" size="xl" color="red">
                    <X size={18} />
                  </ThemeIcon>
                )}
              </td>
              <td style={{ width: "20%" }}>
                <Text size="xl">
                  {this.props.data.dough.name} K{this.props.data.bowl}
                </Text>
                <Text size="xs" weight="bold">
                  TEIG
                </Text>
              </td>
              <td style={{ width: "20%" }}>
                <Text size="xl">{this.props.data.phase.name}</Text>
                <Text size="xs" weight="bold">
                  PHASE
                </Text>
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                <Text size="xl">
                  <Countdown
                    autoStart={false}
                    date={finishDate.toDate()}
                    daysInHours={true}
                  >
                    <Text size="xl" weight="bold" color="red">
                      FERTIG
                    </Text>
                  </Countdown>
                </Text>
                <Text size="xs" weight="bold">
                  RESTZEIT (min.)
                </Text>
              </td>
              <td style={{ textAlign: "right", width: 0 }}>
                <Group noWrap>
                  {!this.isFinished() && (
                    <Button
                      onClick={this.onNext.bind(this)}
                      variant={warning || ended ? "filled" : "outline"}
                    >
                      {processing ? (
                        <ArrowBigRightLines size={18} />
                      ) : (
                        "Nächste Phase"
                      )}
                    </Button>
                  )}
                  <ActionIcon
                    onClick={this.onDelete.bind(this)}
                    size="lg"
                    color="red"
                    variant="outline"
                  >
                    <X size={18} />
                  </ActionIcon>
                </Group>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
    );
  }
}
