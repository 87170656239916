import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { DirectusContext } from "./Provider/Directus"
import Auth from './Screens/Auth'
import { LoadingOverlay } from '@mantine/core';
import Dashboard from "./Screens/Dash";
import TV from "./Screens/Tv";

export default class App extends React.Component {

  state={
    loggedin:false,
    loading: true
  } 

  static contextType = DirectusContext

  async componentDidMount() {
    document.title = "Teig-Monitor"
    await this.checkAuth()
  } 
  async checkAuth() {
    console.log(this.context.directus.auth)
    await this.context.directus.auth
		.refresh()
		.then(() => this.setState({ loading: false,loggedin:true }))
		.catch(() => this.setState({ loading: false,loggedin:false }));
  }
  render() {
    if(this.state.loading){
      return (<LoadingOverlay visible={true} />)
    }
    if(!this.state.loggedin){
      return (<Auth onLogin={this.checkAuth.bind(this)} />)
    }
    return (
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="tv" element={<TV />} />
      </Routes>
    );
  }
}