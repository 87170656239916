import * as React from "react";
import { Header, Group, ActionIcon } from "@mantine/core";
import { Logout, DeviceTv, LayoutDashboard } from "tabler-icons-react";
import { DirectusContext } from "../Provider/Directus";
import { Link } from "react-router-dom";

export default class DefaultHeader extends React.Component {
  static contextType = DirectusContext;
  async onLogout() {
    await this.context.directus.auth.logout();
    window.location.reload();
  }
  render() {
    return (
      <Header height={60} p="xs">
        <Group position="apart" style={{ height: "100%" }}>
          <img
            style={{ height: "100%" }}
            src={require("../Assets/tcy-quadrat.png")}
            alt="TECOYO UG"
          />
          <Group>
            <ActionIcon
              component={Link}
              to="/"
              color="indigo"
              size="lg"
              variant="outline"
            >
              <LayoutDashboard size={16} />
            </ActionIcon>
            <ActionIcon
              component={Link}
              to="/tv"
              color="indigo"
              size="lg"
              variant="filled"
            >
              <DeviceTv size={16} />
            </ActionIcon>
            <ActionIcon
              onClick={this.onLogout.bind(this)}
              color="red"
              size="lg"
              variant="filled"
            >
              <Logout size={16} />
            </ActionIcon>
          </Group>
        </Group>
      </Header>
    );
  }
}
